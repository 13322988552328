<template>
  <div style="width:100%">
    <el-input size="large" :value="modelValue" :readonly="!readonly" :placeholder="placeholder" @click="addModal = true;" />
    <el-dialog
      v-model="addModal"
      class="artist-modal"
      center
      :title="placeholder"
      style="width:50em"
      @close="addModal = false;"
    >
      <div style="width:90%; margin: auto;">
        <div>
          <ArtistForm
            v-for="form in artistCount"
            :key="form.id"
            :modal="addModal"
            :info="form"
            :placeholder="placeholder"
            :iter="form.id"
            @update="updateForm"
            @delete="deleteForm"
          />
        </div>
        <div class="d-flex flex-row justify-end">
          <div style="flex-basis: 70%;">
            <el-button style="padding: 0.5em !important;" link @click="artistCount.push({
              pseudonym: '',
              links: [
                {
                  link: '',
                  platform: '',
                  id:0,
                },
              ],
              id: artistCount.length,
            })"
            >
              <el-icon style="margin-right:1em" type="primary">
                <Plus />
              </el-icon>
              Добавить ещё одного артиста
            </el-button>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button size="large" type="primary" style="" @click="saveForm()">
          Сохранить
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ArtistForm from './ArtistForm.vue';

export default {
  name: 'ArtistsField',
  props: {
    readonly: Boolean,
    modelValue: Object,
    placeholder: String,
    arr: Array,
  },
  data: () => ({
    artistCount: [{
      pseudonym: '',
      links: [
        {
          link: '',
          platform: '',
          id: 0,
        },
      ],
      id: 0,
    }],
    addModal: false,
    array: [],
  }),
  mounted() {
    // if (this.arr.length > 0) {
    //   this.setForm();
    // } else {
    //   this.artistCount.push({
    //     pseudonym: '',
    //     spotify: '',
    //     apple: '',
    //   });
    // }

    // if (this.arrarr.length) {
    //   this.artistCount = this.arr.reduce;
    // }
  },
  watch: {
    addModal() {
      if (this.addModal) {
        if (this.arr.length > 0) {
          this.setForm();
        }
      } else {
        this.artistCount = [{
          id: 0,
          pseudonym: '',
          links: [],
        }];
      }
    },
  },
  methods: {
    setForm() {
      const arr = this.arr.reduce((acc, it, i) => {
        const obj = {
          id: i,
          pseudonym: '',
          links: [],
        };
        obj.links = it.links.map((it, i) => ({ ...it, id: i }));
        obj.pseudonym = it.pseudonym;
        acc.push(obj);
        return acc;
      }, []);
      this.artistCount = arr;
    },
    updateForm(i, form) {
      this.artistCount[i] = { ...form, id: i };
    },
    deleteForm(form) {
      this.artistCount = this.artistCount.filter((it) => it.pseudonym !== form.pseudonym);
    },
    saveForm() {
      this.addModal = false;
      this.$emit('update', this.formattedObject);
    },
  },
  computed: {
    formattedObject() {
      return this.artistCount.map((it) => {
        if (!it.pseudonym) {
          return;
        }
        // const links = [];
        // if (it.spotify) {
        //   links.push({
        //     link: it.spotify,
        //     name: 'spotify',
        //   });
        // }
        // if (it.apple) {
        //   links.push({
        //     link: it.apple,
        //     name: 'apple',
        //   });
        // }
        return {
          pseudonym: it.pseudonym,
          links: it.links,
        };
      });
    },
  },
  components: { ArtistForm },
};

</script>

<style lang="scss">
.artist-modal {
    --el-dialog-padding-primary: 1em;

}
</style>
