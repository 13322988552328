<template>
  <div>
    <el-dialog
      v-model="visible"
      title="Создать шаблон"
      class="purchase-modal"
      style="width:40em"
      @close="close"
    >
      <template #header="{ }">
        <div>
          Создать шаблон
        </div>
      </template>
      <div v-if="templatesList.length === 0" style="text-align:  center;" class="my2">
        Нет шаблонов
      </div>
      <div>
        <div v-for="templateState in templatesList" :key="templateState.id" class="template-row" :class="{'template-row--setted' : settedTemplate && templateState.id === settedTemplate.id}" @click="settedTemplate = templateState">
          <div>
            {{ templateState.name }}
          </div>
          <div>
            <el-icon size="30" @click="deleteTemplate(templateState.id)">
              <Delete />
            </el-icon>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="purchase-modal__btn">
          <el-button type="primary" :disabled="!settedTemplate" :loading="loading" @click="setTemplate">
            Выбрать
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { required, minLength, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
  Delete,
} from '@/assets/icons/index.js';
import {
  ElMessage,
} from 'element-plus';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: 'AddTemplateModal',
  props: {
    template: Object,
    modelValue: Boolean,
  },
  components: {
    Delete,
  },
  data: () => ({
    allow: false,
    loading: false,
    templatesList: [],
    settedTemplate: null,
  }),
  computed: {
    allowSubmit() {
      return this.v$.$invalid;
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.getTemplate();
      }
    },
  },
  methods: {
    async setTemplate() {
      this.$emit('set-template', this.settedTemplate.content);
      this.close();
    },
    async deleteTemplate(templateId) {
      this.loading = true;
      try {
        await this.$axios.post(`/moderator-template-message/delete-template-message?templateId=${templateId}`);
        this.getTemplate();
        ElMessage({ message: 'Шаблон успешно удалён', type: 'success' });
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async getTemplate() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get('/moderator-template-message/get-template-messages');
        this.templatesList = data;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    getErrorMessage(fieldName) {
      return this.$errorsMessages(this.v$, `form.${fieldName}`);
    },
    close() {
      this.visible = false;
      this.templatesList = [];
      this.settedTemplate = null;
    },
  },
  validations() {
    return {
      form: {
        name: { required, minLength: minLength(2), maxLength: maxLength(255) },
        content: { required, minLength: minLength(5), maxLength: maxLength(2000) },
      },
    };
  },

};
</script>

<style lang="scss">
.template-row{
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  font-size: 1.3em;
  transition: 0.2s;
  border-radius: 0.5em;
  &:hover {
    background-color: $whiteOpacity20;
  }
}
  .purchase-modal{

    &__btn{

    }
    .el-dialog{
      &__header{
        font-size: 1.7777777777777777em;
        padding:1.6666666666666667em 2.5em  0.8333333333333334em 2.5em!important;
        text-align: left !important;
        border-bottom: $whiteOpacity 1px solid;
        margin:0;

        &btn{
          font-size: 0.8em;
          top: 26px !important;
          right: 1.6666666666666667em !important;
        }
      }

      &__body{
        // padding:1.1111111111111112em 2.5em 1.1111111111111112em 1.6666666666666667em!important;
        // border-bottom: $whiteOpacity 1px solid;
      }

      &__footer{
        // padding:1.1111111111111112em 2.5em 1.6666666666666667em 1.6666666666666667em!important;
      }
    }
  }
</style>
