<template>
  <el-container class="a-container">
    <div style="width: 90%">
      <div class="ar-h1 mb3">Создание релиза</div>
      <el-alert
        v-if="isModerator && savedUserComment"
        :title="`Комментарий модератору: ${savedUserComment}`"
        type="warning"
        :closable="false"
      />
      <el-alert
        v-if="isModerator && moderComment"
        :title="`Комментарий пользователя: ${moderComment}`"
        type="warning"
        :closable="false"
      />
      <el-tabs ref="tabs" v-model="activeName" class="release-custom-tab">
        <el-tab-pane :disabled="loading" label="Релиз" name="release">
          <!-- <template #label>
            <span class="custom-tabs-label">
              <span :style="hasError?'color:#f56c6c':null" class="mr2">Релиз</span>
              <el-icon v-if="hasError" color="#f56c6c" class="mt10"><Warning /></el-icon>
            </span>
          </template> -->
          <div class="ar-card" style="padding: 2em">
            <!--eslint-disable-->
            <ReleaseTab
              :id="id"
              ref="releaseTab"
              v-model:selectedGenre="selectedGenre"
              v-model:imagePath="imagePath"
              v-model:draftLoader="draftLoader"
              v-model:languages="languages"
              v-model:releaseTypes="releaseTypes"
              v-model:form="form"
              :published="published"
              @set-user-comment="setUserComment"
              @set-comment="setComment"
              @has-file="setHasFile"
              @add-error="addError"
              @set-id="setReleaseId"
            />
            <!--eslint-enable-->
          </div>
        </el-tab-pane>
        <el-tab-pane :disabled="loading" label="Трек-лист" name="tracks">
          <TrackTab
            v-if="form"
            :id="releaseId"
            ref="trackTab"
            :status="form.status"
            :head-artists="form.headArtists"
            @has-track="setHasTrack"
          />
        </el-tab-pane>
        <el-tab-pane :disabled="loading" label="Проверка" name="verification">
          <template v-if="!isModerator">
            <div>
              <template v-if="hasError || !hasTrack || !hasFile">
                <div class="ar-card mt4">
                  <div class="">
                    <div class="d-flex flex-row align-center ar-h3 pt4" style="margin-left: 1.3em">
                      <el-icon color="red" size="25" class="mr2">
                        <Warning />
                      </el-icon>
                      Ошибки в релизе
                    </div>
                    <div class="d-flex flex-row flex-wrap ar-subheader-1 ph4 pb4">
                      <div
                        v-if="moderComment"
                        style="flex-basis: 33.33333%"
                        class="mt3 d-flex flex-row align-center"
                      >
                        <div
                          style="
                            width: 0.5em;
                            height: 0.5em;
                            background-color: red;
                            border-radius: 50%;
                          "
                          class="mr2"
                        />
                        <div class="ph3">
                          Комментарий модератора: <br />
                          <span>{{ moderComment }}</span>
                        </div>
                      </div>
                      <div
                        v-if="!hasTrack"
                        style="flex-basis: 33.33333%"
                        class="mt3 d-flex flex-row align-center"
                      >
                        <div
                          style="
                            width: 0.5em;
                            height: 0.5em;
                            background-color: red;
                            border-radius: 50%;
                          "
                          class="mr2"
                        />
                        <div class="ph3">Для загрузки необходим <span>минимум 1 трек</span></div>
                      </div>
                      <div
                        v-if="!hasFile"
                        style="flex-basis: 33.33333%"
                        class="mt3 d-flex flex-row align-center"
                      >
                        <div
                          style="
                            width: 0.5em;
                            height: 0.5em;
                            background-color: red;
                            border-radius: 50%;
                          "
                          class="mr2"
                        />
                        <div class="ph3">Загрузите обложку</div>
                      </div>
                      <template v-for="(value, name) in errors">
                        <div
                          v-if="value"
                          :key="name"
                          class="d-flex flex-row align-center mt3"
                          style="flex-basis: 33.33333%"
                        >
                          <div
                            style="
                              min-width: 0.5em;
                              height: 0.5em;
                              background-color: red;
                              border-radius: 50%;
                            "
                            class="mr2"
                          />
                          <div class="ph3">
                            {{ errorEnum[name] }} - <span v-if="value"> {{ value }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  v-if="moderComment"
                  style="border-left: 4px solid rgb(245, 108, 108)"
                  class="mb4"
                >
                  <ul class="ml4">
                    <li>
                      Комментарий модератора: <br />
                      <span style="color: rgb(245, 108, 108)">{{ moderComment }}</span>
                    </li>
                  </ul>
                </div>
                <div style="display: flex" class="mb4">
                  <div
                    class="pa5 ar-card"
                    style="
                      flex-basis: 27.77777777777778em;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                    "
                  >
                    <div style="display: flex; flex-direction: column; justify-content: center">
                      Релиз прошёл проверку теперь его можно опубликовать
                      <el-button
                        type="primary"
                        size="large"
                        :loading="loading"
                        class="mt4"
                        :disabled="draftLoader"
                        @click="publishRelease"
                      >
                        Опубликовать релиз
                      </el-button>
                    </div>
                  </div>
                </div>
              </template>
              <el-form
                v-if="form"
                label-position="right"
                label-width="15.555555555555555em"
                class="ar-card mt5 pa4"
              >
                <div
                  style="width: 100%; padding-top: 12px; display: flex; flex-direction: row"
                  justify-content:stretch
                >
                  <div
                    style="
                      padding: 0 1.3333333333333333em 1.1111111111111112em 0px;
                      justify-content: center;
                      display: flex;
                      flex-direction: column;
                      justify-content: start;
                      align-content: center;
                    "
                  >
                    <div>
                      <div>
                        <el-image ref="image" class="release-image" fit="cover" :src="imagePath">
                          <template #error>
                            <div class="image-slot">
                              <el-icon size="50">
                                <Catalog />
                              </el-icon>
                            </div>
                          </template>
                        </el-image>
                      </div>
                    </div>
                  </div>
                  <div
                    class="verification__form"
                    style="display: flex; flex-direction: column; flex-basis: 50em"
                  >
                    <div class="verification__row">
                      <div>Название релиза</div>
                      <div class="verification__field">
                        {{ form.name || "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Версия релиза</div>
                      <div class="verification__field">
                        {{ form.version || "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Основной артист</div>
                      <div class="verification__field">
                        {{ form.headArtists.length ? form.headArtists.join(", ") : "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Дополнительный артист</div>
                      <div class="verification__field">
                        {{ form.collaborators.length ? form.collaborators.join(",") : "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Жанр</div>
                      <div class="verification__field">
                        {{ selectedGenre || "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Дата релиза</div>
                      <div class="verification__field">
                        {{ form.releaseDate || "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Дата предзаказа</div>
                      <div class="verification__field">
                        {{ form.preOrderDate || "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Тип релиза</div>
                      <div v-if="releaseTypes && form.type >= 0" class="verification__field">
                        {{
                          releaseTypes.filter((it) => {
                            return it.id === form.type;
                          })[0].name
                        }}
                      </div>
                      <div v-else>-</div>
                    </div>
                    <div class="verification__row">
                      <div>Язык релиза</div>
                      <div class="verification__field">
                        {{
                          languages.filter((it) => {
                            return it.id === form.language;
                          })[0].name || "-"
                        }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Copyright</div>
                      <div class="verification__field">
                        {{ form.copyright || "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>UPC релиза</div>
                      <div class="verification__field">
                        {{ form.upc || "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Время в TikTok</div>
                      <div class="verification__field">
                        {{ form.tikTokTime || "-" }}
                      </div>
                    </div>
                    <div class="verification__row">
                      <div>Инструментал</div>
                      <div class="verification__field">
                        {{ form.isInstrumental ? "Да" : "Нет" }}
                      </div>
                    </div>
                    <div class="verification__commentary d-flex">
                      <div class="pt3">Комментарий для модератора</div>
                      <div>
                        <el-input v-model="userComment" :rows="10" type="textarea" />
                      </div>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </template>
          <template v-else>
            <ApproveReleaseTab :id="id" :status="form.status" />
            <SubmitButton :id="id" />
          </template>
        </el-tab-pane>
        <!-- <el-tab-pane label="Оплата" name="payment">
          <PurchaseTab />
        </el-tab-pane> -->
      </el-tabs>
      <el-button
        v-show="activeName === 'tracks'"
        ref="btn"
        circle
        type="primary"
        class="ml4"
        @click="openTrackModal()"
      >
        <el-icon size="2em">
          <Plus />
        </el-icon>
      </el-button>
    </div>
  </el-container>
</template>

<script>
import ReleaseTab from "@/components/create-release/ReleaseTab.vue";
import TrackTab from "@/components/create-release/TrackTab.vue";
import ApproveReleaseTab from "@/components/create-release/ApproveReleaseTab.vue";
// import PurchaseTab from '@/components/create-release/PurchaseTab.vue';
import { ElMessage, ElMessageBox } from "element-plus";
import { mapGetters } from "vuex";
import SubmitButton from "./SubmitButton.vue";

export default {
  name: "CreateRelease",
  props: {
    id: String,
  },
  data: () => ({
    savedUserComment: null,
    userComment: null,
    languages: [],
    releaseTypes: [],
    selectedGenre: null,
    draftLoader: false,
    moderComment: null,
    published: false,
    loading: false,
    hasFile: false,
    hasTrack: false,
    errors: {},
    imagePath: false,
    form: null,
    releaseId: "",
    activeName: "release",
    errorEnum: {
      language: "Язык",
      name: "Наименование релиза",
      version: "Версия",
      headArtist: "Главный артист",
      copyright: "Копирайт",
      UPC: "UPC",
      releaseLabel: "Лейбл",
      publisherLabel: "Лейбл",
      collaborators: "Дополнительный артист",
      genre: "Жанр",
      releaseDate: "Дата релиза",
      preOrderDate: "Дата предзаказа",
      type: "Тип",
      obscenities: "Мат",
      releaseSites: "Площадки релиза",
      year: "Год",
    },
  }),
  components: {
    ApproveReleaseTab,
    // PurchaseTab,
    ReleaseTab,
    TrackTab,
    SubmitButton,
  },
  mounted() {
    if (this.id) {
      this.setReleaseId(this.id);
    }
    const scrollBar = this.$refs.tabs.$el.querySelector(".el-tabs__header");
    scrollBar.appendChild(this.$refs.btn.$el);
  },
  computed: {
    ...mapGetters(["isModerator"]),
    hasError() {
      const errors = Object.values(this.errors);
      return !!errors.join("");
    },
  },
  methods: {
    openTrackModal() {
      this.$refs.trackTab.openDialog();
    },
    async publishRelease() {
      this.loading = true;
      const params = {
        comment: this.userComment,
      };
      await this.$axios
        .post(`/release-catalog/publish-release-by-draft-id/${this.releaseId}`, params)
        .then(() => {
          this.published = true;
          ElMessage({ message: "Релиз успешно опубликован", type: "success" });
          this.$router.push("/release-list");
        })
        .catch((e) => {
          ElMessage({ message: e.response.data[0], type: "error" });
        });
      this.loading = false;
    },
    setHasFile(v) {
      this.hasFile = v;
    },
    setHasTrack(v) {
      this.hasTrack = v;
    },
    setUserComment(v) {
      this.savedUserComment = v;
    },
    setComment(v) {
      this.moderComment = v;
    },
    addError(field, text) {
      this.errors[field] = text;
    },
    setReleaseId(id) {
      this.releaseId = id;
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.id && !this.published && !this.isModerator) {
      try {
        await ElMessageBox.confirm("Вы хотите сохранить черновик?", "Внимание", {
          confirmButtonText: "Сохранить",
          cancelButtonText: "Нет",
          type: "warning",
        });
        await this.$refs.releaseTab.saveReleaseDraft();
        ElMessage({
          message: "Черновик успешно сохранён",
          type: "success",
        });
      } catch (e) {
        await this.$axios.delete(`release-catalog/delete-release-by-id/${this.releaseId}`);
        ElMessage({
          message: "Не сохранён",
          type: "error",
        });
      } finally {
        next();
      }
    } else {
      next();
    }
  },
  watch: {
    id() {
      this.activeName = "release";
      this.setReleaseId(this.id);
    },
    activeName() {
      this.$refs.releaseTab.validateForm();
      if (!this.published || this.isModerator) {
        this.$refs.releaseTab.saveReleaseDraft();
      }
    },
  },
};
</script>

<style lang="scss">
.release-custom-tab {
  .el-tabs__content {
    overflow: visible !important;
  }
  .el-tabs__header {
    align-items: center;
    justify-content: start;
  }

  .el-tabs__nav-wrap {
    flex: none !important;
  }
}
.verification {
  &__commentary {
    & :first-child {
      text-align: left;
      font-weight: 500;
      font-size: 1em;
      display: flex;
      align-items: top;
      min-width: 260px;
      justify-content: end;
      color: $primaryGrey;
      margin-right: 2em;
    }
  }

  &__field {
    cursor: pointer;
    padding: 0 1.3888888888888888em;
    display: flex;
    align-items: center;
    height: 3.056em;
    flex-basis: 100%;
    border-bottom: 1px solid $subGrey2;

    &:hover {
      border-bottom: 1px solid $primaryBlue;
    }
  }
  &__row {
    margin-bottom: 1.6666666666666667em;
    height: 3.056em;
    display: flex;

    & :first-child {
      font-weight: 500;
      font-size: 1em;
      display: flex;
      align-items: center;
      min-width: 260px;
      justify-content: end;
      color: $primaryGrey;
      margin-right: 2em;
    }
  }
  &__form {
  }
}
</style>
