<template>
  <div>
    <el-dialog
      v-model="visible"
      title="Создать шаблон"
      class="purchase-modal"
      style="width:40em"
      @close="close"
    >
      <template #header="{ }">
        <div>
          Создать шаблон
        </div>
      </template>
      <div style="width:100%">
        <el-form>
          <el-form-item :error="getErrorMessage('name')">
            <el-input v-model="form.name" placeholder="Название шаблона" @blur="v$.form.name.$touch()" />
          </el-form-item>
          <el-form-item :error="getErrorMessage('content')">
            <el-input v-model="form.content" type="textarea" :rows="5" placeholder="Текст" @blur="v$.form.content.$touch()" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="purchase-modal__btn">
          <el-button v-if="template" type="primary" :disabled="allowSubmit" :loading="loading" @click="updateNews">
            Обновить
          </el-button>
          <el-button v-else type="primary" :disabled="allowSubmit" :loading="loading" @click="addTemplate">
            Создать
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { required, minLength, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
  ElMessage,
} from 'element-plus';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: 'AddTemplateModal',
  props: {
    template: Object,
    modelValue: Boolean,
  },
  data: () => ({
    allow: false,
    loading: false,
    form: {
      name: null,
      content: null,
    },
  }),
  computed: {
    allowSubmit() {
      return this.v$.$invalid;
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    visible() {
      if (this.news) {
        this.form = {
          name: this.news.name,
          content: this.news.content,
        };
      }
    },
  },
  methods: {
    async addTemplate() {
      this.loading = true;
      try {
        const params = { ...this.form };
        await this.$axios.post('/moderator-template-message/create-template-message', params);
        this.close();
        this.$emit('update');
        ElMessage({ message: 'Шаблон добавлен', type: 'success' });
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    getErrorMessage(fieldName) {
      return this.$errorsMessages(this.v$, `form.${fieldName}`);
    },
    close() {
      this.visible = false;
      this.form = {
        name: null,
        content: null,
      };
    },
  },
  validations() {
    return {
      form: {
        name: { required, minLength: minLength(2), maxLength: maxLength(255) },
        content: { required, minLength: minLength(5), maxLength: maxLength(2000) },
      },
    };
  },

};
</script>
