<template>
  <div>
    <el-dialog
      v-model="visible"
      center
      style="width:55%"
      @close="close"
    >
      <template #header>
        <div class="ar-h1">
          {{ hasTrack ? 'Изменить трек' :'Новый трек' }}
        </div>
      </template>
      <!-- <div class="drop-zone">
        Добавить сюда
      </div> -->
      <input
        ref="inputFile"
        style="display:none"
        type="file"
        @change="loadImage"
      >
      <el-form label-position="right" label-width="16em">
        <el-form-item :error="getErrorMessage('name')" label="Название трека">
          <el-input v-model="form.name" size="large" :readonly="!canEdit" placeholder="Название трека" @blur="v$.form.name.$touch()" />
        </el-form-item>
        <el-form-item :error="getErrorMessage('version')" label="Версия трека">
          <el-input v-model="form.version" size="large" :readonly="!canEdit" placeholder="Версия" @blur="v$.form.version.$touch()" />
        </el-form-item>
        <el-form-item :error="getErrorMessage('headArtists')" label="Основной артист">
          <!--eslint-disable-->
          <ArtistsField
            v-model="headArtists"
            v-model:arr="form.headArtists"
            :readonly="!canEdit"
            placeholder="Основной артист"
            @update="setHeadArtsits"
          />
          <!--eslint-enable-->
        </el-form-item>
        <el-form-item label="Дополнительный артист">
          <!--eslint-disable-->
            <ArtistsField
            v-model="collaborators"
            v-model:arr="form.collaborators"
            :readonly="!canEdit"
            placeholder="Дополнительный артист"
            @update="setCollaborators"
          />
          <!--eslint-enable-->
        </el-form-item>
        <el-form-item :error="getErrorMessage('author')" label="Автор текста">
          <el-input v-model="form.author" size="large" :readonly="!canEdit" placeholder="Автор текста" @blur="v$.form.author.$touch()" />
        </el-form-item>
        <el-form-item :error="getErrorMessage('composer')" label="Композитор">
          <el-input v-model="form.composer" size="large" :readonly="!canEdit" placeholder="Композитор" @blur="v$.form.composer.$touch()" />
        </el-form-item>
        <el-form-item label="Отрывок в TikTok">
          <el-input v-model="form.tikTokTime" size="large" :readonly="!canEdit" placeholder="Отрывок в TikTok" />
        </el-form-item>
        <el-form-item :error="getErrorMessage('isrc')" label="ISRC">
          <template #label>
            <div style="display: flex; align-items: center;">
              <el-tooltip
                effect="light"
                content=""
                placement="top"
              >
                <template #content>
                  <div style="width:400px">
                    ISRC - поле для указания ISRC кода, который нужен при переносе релиза с одного дистрибутора на другой. Если Ваш релиз новый, то оставьте поле пустым.
                  </div>
                </template>
                <el-icon size="1.5em" class="mr2">
                  <Info />
                </el-icon>
              </el-tooltip>
              ISRC
            </div>
          </template>
          <el-input v-model="form.isrc" size="large" :readonly="!canEdit" placeholder="ISRC" @blur="v$.form.isrc.$touch()" />
        </el-form-item>
        <el-form-item :error="getErrorMessage('text')" label="Текст">
          <el-input v-model="form.text" size="large" type="textarea" placeholder="Текст" @blur="v$.form.text.$touch()" />
        </el-form-item>
        <el-form-item :error="getErrorMessage('hasProfanity')" label="Наличие ненормативной лексики">
          <el-switch v-model="form.hasProfanity" :readonly="!canEdit" />
        </el-form-item>
        <div class="d-flex flex-row align-center">
          <div style="flex-basis:21em" />
          <div style="flex-basis:100%;justify-content: start;" class="d-flex flex-row">
            <div v-show="files.length !== 0">
              <el-tag size="large" round effect="dark" type="info" class="file-tag mr3">
                {{ fileName }}
                <el-icon @click="files = []; filesInfo=null; clearFile();">
                  <Close />
                </el-icon>
              </el-tag>
            </div>

            <div style="flex-grow: 1;" class="pl2 pt2">
              <el-progress
                v-show="loading"
                :percentage="uploadPercentage"
                stroke-width="1em"
                :duration="10"
              />
            </div>
          </div>
        </div>
      </el-form>
      <template v-if="canEdit " #footer>
        <div v-if="!hasTrack ">
          <div v-if="files.length === 0" class="dialog-footer">
            <el-button type="primary" size="large" @click="$refs.inputFile.click()">
              Добавить трек
            </el-button>
          </div>
          <template v-else>
            <div class="dialog-footer">
              <el-button type="primary" :disabled="allowSubmit" :loading="loading" @click="uploadTrack();">
                <template v-if="!uploadPercentage">
                  Сохранить
                </template>
                <span v-if="uploadPercentage" style="margin-left: 0.5555555555555556em;"> {{ uploadPercentage === 100 ? 'Идёт обработка сервером' : `Загрузка` }}</span>
              </el-button>
            </div>
          </template>
        </div>
        <div v-else class="dialog-footer">
          <el-button type="primary" size="large" :loading="loading" @click="updateTrack();">
            Сохранить трек
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  ElMessage,
} from 'element-plus';
import { required, minLength, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import {
  Info,
} from '@/assets/icons/index.js';
import ArtistsField from '../common/ArtistsField.vue';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: 'CreateTrackModal',
  components: {
    Info,
    ArtistsField,
  },
  props: {
    headArtist: Array,
    status: Number,
    id: String,
    modelValue: Boolean,
    track: Object,
  },
  data: () => ({
    uploadPercentage: 0,
    platforms: [
      { name: 'Spotify', id: 'spotify' },
      { name: 'Apple Music', id: 'apple' },
      { name: 'Yandex Music', id: 'yandex' },
      { name: 'VK Music', id: 'vk' },
    ],
    platformForm: {
      platform: {
        name: '',
        link: '',
      },
      pseudonym: '',
      type: '',
    },
    showModal: false,
    collaborator: null,
    // headArtist: '',
    loading: false,
    form: {
      name: null,
      version: null,
      headArtist: null,
      collaborators: [],
      author: null,
      isrc: null,
      composer: null,
      hasProfanity: false,
      headArtists: [],
      text: null,
      tikTokTime: null,
    },
    files: [],
    inputFile: null,
    filesInfo: null,
  }),
  computed: {
    ...mapGetters(['isModerator']),
    fileName() {
      return this.filesInfo ? `${this.filesInfo[0]}.${this.filesInfo[1]}` : '';
    },
    canEdit() {
      return !this.isModerator || (this.isModerator && (this.status === 7 || this.status === 3));
    },
    headArtists() {
      if (this.form.headArtists.length === 0) {
        return [];
      }
      return this.form.headArtists.reduce((acc, cur) => { acc.push(cur.pseudonym); return acc; }, []).join(',');
    },
    hasTrack() {
      return !!this.track;
    },
    collaborators() {
      if (this.form.collaborators.length === 0) {
        return [];
      }
      return this.form.collaborators.reduce((acc, cur) => { acc.push(cur.pseudonym); return acc; }, []).join(',');
    },
    allowSubmit() {
      return this.v$.$invalid;
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    visible() {
      if (this.hasTrack) {
        this.setTrack();
      }
      if (this.visible) {
        this.v$.$reset();
        this.form.headArtists = this.headArtist.reduce((acc, it) => {
          if (it.pseudonym) {
            acc.push(it);
          } else {
            acc.push({
              pseudonym: it,
              links: [{
                platform: null,
                link: null,
                id: 0,
              }],
            });
          }
          return acc;
        }, []);
      }
    },
  },
  methods: {
    openAddPlatformModal(user, type) {
      this.platformForm.platform.link = user.platform.link;
      this.platformForm.platform.name = user.platform.name;
      this.platformForm.pseudonym = user.pseudonym;
      this.platformForm.type = type;
      this.showModal = true;
    },
    setTrack() {
      const {
        name, version, headArtists,
        collaborators,
        author,
        isrc,
        composer,
        hasProfanity,
        text,
        tikTokTime,
      } = this.track;
      this.form = {
        name,
        version,
        headArtists,
        collaborators: collaborators[0] ? collaborators : [],
        author,
        isrc,
        composer,
        hasProfanity,
        text,
        tikTokTime,
      };
    },
    deleteColloborator(i) {
      this.form.collaborators.splice(i, 1);
    },
    addColloborator() {
      this.form.collaborators.push({
        pseudonym: this.collaborator,
        platform: {
          name: null,
          link: null,
        },
      });
      this.collaborator = null;
    },
    setCollaborators(val) {
      this.form.collaborators = val;
    },
    setHeadArtsits(val) {
      this.form.headArtists = val;
    },
    deleteHeadArtist(i) {
      this.form.headArtists.splice(i, 1);
    },
    getErrorMessage(fieldName) {
      return this.$errorsMessages(this.v$, `form.${fieldName}`);
    },
    async  createFile(file) {
      this.filesInfo = file.name.split('.');

      this.files.push(file);
    },
    async  loadImage(e) {
      e.preventDefault();
      const files = e.target.files || e.dataTransfer.files;
      const data = this;
      const reader = new FileReader();

      reader.onload = function (e) { // eslint-disable-line
        data.preview = e.target.result;
      };
      reader.readAsDataURL(files[0]);
      for (const file of files) {
        const ext = `.${file.name.split('.').pop().toLowerCase()}`;
        if (ext !== '.wav') {
          ElMessage({ message: 'Допустимое расширение файла .wav', type: 'error' });
          this.$refs.inputFile.value = null;
          return;
        }
        this.createFile(file);
      }
    },
    clearFile() {
      this.$refs.inputFile.value = null;
    },
    async updateTrack() {
      this.loading = true;
      const form = {
        ...this.form,
      };
      await this.$axios.post(`/tracks/edit-track?id=${this.track.id}`, form)
        .then(() => {
          ElMessage({ message: 'Трек успешно обновлен', type: 'success' });
          this.$emit('update');
          this.close();
        })
        .catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async uploadTrack() {
      const form = {
        ...this.form,
      };
      this.loading = true;
      const formData = new FormData();
      for (const it in form) {
        if (form[it]) {
          if (it === 'collaborators' || it === 'headArtists') {
            formData.append(`${it}`, JSON.stringify(this.form[it]));
          } else {
            formData.append(`${it}`, this.form[it]);
          }
        }
      }
      formData.append('hasProfanity', form.hasProfanity);
      formData.append('audio', this.files[0]);
      formData.append('releaseId', this.id);
      await this.$axios.post('/tracks/upload-track', formData,
        { onUploadProgress: (progressEvent) => { this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10); } })
        .then(() => {
          ElMessage({ message: 'Трек успешно загружен', type: 'success' });
          this.$emit('update');
          this.close();
        })
        .catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.form = {
        name: null,
        version: null,
        headArtists: [],
        collaborators: [],
        author: null,
        isrc: null,
        composer: null,
        hasProfanity: false,
        artistsLinks: [],
        text: null,
        tikTokTime: null,
      };
      this.$emit('clear-track');
      this.files = [];
      this.$refs.inputFile.value = null;
      this.uploadPercentage = 0;
      this.visible = false;
    },
  },

  validations() {
    return {
      form: {
        name: { required, minLength: minLength(2), maxLength: maxLength(255) },
        version: { minLength: minLength(2), maxLength: maxLength(255) },
        headArtists: { required },
        author: { required, minLength: minLength(5), maxLength: maxLength(255) },
        isrc: { minLength: minLength(5), maxLength: maxLength(255) },
        composer: { required, minLength: minLength(5), maxLength: maxLength(255) },
        text: { minLength: minLength(5), maxLength: maxLength(2000) },
      },
    };
  },
};
</script>

<style lang="scss">

.file-tag{
  .el-tag__content{
    display: flex;
    align-items: center;
}
  border-color: transparent;
  color:$primaryGrey;
  --el-tag-bg-color:#EFF3F8 !important;
  align-items: center;
}
.el-dialog{
  &__header{
    &btn{
      font-size: 1.5em;
      top: 1em !important;
      right:1em !important;
;
    }
  }
}

.add-platform {
  z-index:3000;
  .el-dialog__header{
    display: none;
  }
}

.add-button{
  font-size: 1rem;
  width:3em;
  height: 1em;
}

// .link-button{
//   height:2.9rem;
// }

.drop-zone{
  border:white 1px dashed;
  position: absolute;
  display: flex;
  width:100%;
  height:100%;
  margin-left:-1.1111111111111112em;
}
</style>
