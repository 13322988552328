<template>
  <div>
    <div v-if="artistName && isModerator">
      <div>
        Тариф: {{ artistTariff }}
      </div>
      <div>
        Пользователь: {{ artistName }}
      </div>
    </div>
    <input
      ref="inputFile"
      style="display:none"
      type="file"
      @change="loadImage"
    >
    <div>
      <div v-if="loaders.openDraft" id="form" style="width: 100%; height:1000px;" />
      <el-form v-else class="form" label-position="right" label-width="16em">
        <div style="width:100%; padding-top:0.6666666666666666em;display: flex; flex-direction: row;" justify-content:stretch>
          <div style="padding:0 1.3333333333333333em 1.3333333333333333em 0px; justify-content:center; display:flex; flex-direction:column; justify-content:start; align-content:center;">
            <div>
              <div>
                <el-image
                  ref="image"
                  class="release-image"
                  :src="preview"
                  fit="cover"
                >
                  <template #error>
                    <div class="image-slot">
                      <el-icon size="50">
                        <Catalog />
                      </el-icon>
                    </div>
                  </template>
                </el-image>
              </div>

              <div v-if="!isModerator" style="width:14.444444444444445em; display:flex; flex-direction: row; justify-content: center;">
                <el-button size="large" type="primary" @click="$refs.inputFile.click()">
                  Загрузить
                </el-button>
              </div>
            </div>
          </div>

          <div style="display: flex; flex-direction:column; flex-basis: 50em;">
            <el-form-item
              label="Название релиза"
              :error="getErrorMessage('name')"
            >
              <el-input ref="name" v-model="form.name" clearable size="large" :readonly="!canEdit" @blur="v$.form.name.$touch()" />
            </el-form-item>
            <el-form-item
              label="Версия релиза"
              :error="getErrorMessage('version')"
            >
              <template #label>
                <div style="display: flex; align-items: center;">
                  <el-tooltip
                    effect="light"
                    content=""
                    placement="top"
                  >
                    <template #content>
                      <div style="width:400px">
                        Версия - поле для указания версии релиза, которая будет отображена во ВКонтакте. Допустимо использовать надписи "remix", "radio edit", "speed up", "slowed". Если Ваш релиз не имеет данных приписок, то оставьте поле пустым.
                      </div>
                    </template>
                    <el-icon size="1.2em" class="mr2">
                      <Question />
                    </el-icon>
                  </el-tooltip>
                  Версия релиза
                </div>
              </template>
              <el-input ref="version" v-model="form.version" clearable size="large" :readonly="!canEdit || artistTariff=== 'Базовый' " @blur="v$.form.version.$touch()" />
            </el-form-item>
            <el-form-item
              label="Основной артист"
              :error="getErrorMessage('headArtists')"
            >
              <el-dropdown style="width:100%" :disabled="!canEdit">
                <el-input ref="headArtists" v-model="form.headArtists" size="large" readonly @blur="v$.form.headArtists.$touch()" />
                <template #dropdown>
                  <div style="display: flex; flex-direction: column;">
                    <div v-for="collaboratorName, i in form.headArtists" :key="i" class="collobarator">
                      <div>
                        {{ collaboratorName }}
                      </div>
                      <div>
                        <el-button link type="danger" @click="deleteHeadArtist(i)">
                          <el-icon v-if="!hideMainArtistCloseButton(collaboratorName)" size="20">
                            <Close />
                          </el-icon>
                        </el-button>
                      </div>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row;" class="ma2">
                    <el-input v-model="artist" clearable size="large" :readonly="!canEdit" class="mr2" placeholder="Основной артист" />
                    <el-button :disabled="!artist" size="large" type="primary" style="" @click="addMainArtist()">
                      +
                    </el-button>
                  </div>
                </template>
              </el-dropdown>
            </el-form-item>
            <el-form-item label="Дополнительный артист">
              <el-dropdown style="width:100%" :disabled="!canEdit">
                <el-input v-model="collaborators" size="large" readonly />
                <template #dropdown>
                  <div style="display: flex; flex-direction: column;">
                    <div v-for="collaboratorName, i in form.collaborators" :key="i" class="collobarator">
                      <div>
                        {{ collaboratorName }}
                      </div>
                      <div>
                        <el-button link style="" type="danger" @click="deleteColloborator(i)">
                          <el-icon size="20">
                            <Close />
                          </el-icon>
                        </el-button>
                      </div>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row;" class="ma2">
                    <el-input v-model="collaborator" size="large" :readonly="!canEdit" class="mr2" placeholder="Дополнительный артист" />
                    <el-button :disabled="!collaborator" size="large" type="primary" style="" @click="addColloborator()">
                      +
                    </el-button>
                  </div>
                </template>
              </el-dropdown>
            </el-form-item>
            <el-form-item label="Жанр" :disabled="isModerator" :error="getErrorMessage('genre')">
              <el-tree-select
                v-if="genres"
                v-model="form.genre"
                clearable
                size="large"
                popper-class="release-popper"
                check-strictly
                :readonly="!canEdit"
                :props="{
                  children:'subGenres',
                  label:'name'
                }"
                value-key="id"
                :render-after-expand="true"
                :data="genres"
                :loading="loaders.genre"
                @blur="v$.form.genre.$touch()"
                @node-click="selectNode"
              />
            </el-form-item>
            <div class="d-flex flex-row date-row" style="">
              <el-form-item id="release" style="height:2.7777777777777777em;width:100%" label="Дата релиза" :error="getErrorMessage('releaseDate')">
                <el-date-picker v-model="form.releaseDate" size="large" :readonly="!canEdit" style="width:100%" @blur="v$.form.releaseDate.$touch()" />
              </el-form-item>
              <el-form-item id="preOrder" style="height:2.7777777777777777em;width:100%" label="Дата предзаказа" :error="getErrorMessage('preOrderDate')">
                <template #label>
                  <div style="display: flex; align-items: center;">
                    <el-tooltip
                      effect="light"
                      content=""
                      placement="top"
                    >
                      <template #content>
                        <div style="width:400px">
                          Дата предзаказа - дата, в которую релиз будет доступен на площадке Apple Music для сохранения и предпросмотра.
                        </div>
                      </template>
                      <el-icon size="1.2em" class="mr2">
                        <Question />
                      </el-icon>
                    </el-tooltip>
                    Дата предзаказа
                  </div>
                </template>
                <el-date-picker v-model="form.preOrderDate" size="large" :readonly="!canEdit" style="width:100%" @blur="v$.form.preOrderDate.$touch()" />
              </el-form-item>
            </div>

            <el-form-item class="mt-2" label="Тип релиза" :error="getErrorMessage('type')">
              <el-radio-group v-if="releaseTypes" v-model="form.type" class="ml-4" :disabled="!canEdit" style="width:100%" @blur="v$.form.type.$touch()">
                <!-- <el-radio
                  v-for="item in releaseTypes"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                  {{ item.name }}
                </el-radio> -->
                <el-radio

                  :label="0"
                >
                  Single
                </el-radio>
                <el-radio
                  :label="1"
                >
                  EP
                </el-radio>
                <el-radio
                  :label="2"
                >
                  Album
                </el-radio>
              </el-radio-group>
              <!-- <el-select v-if="releaseTypes" v-model="form.type" popper-class="release-popper" :disabled="!canEdit" style="width:100%" size="large" @blur="v$.form.type.$touch()">
                <el-option
                  v-for="item in releaseTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select> -->
            </el-form-item>
            <el-form-item label="Язык релиза" :error="getErrorMessage('language')">
              <el-select v-if="languages" v-model="form.language" clearable popper-class="release-popper" :readonly="!canEdit" class="m-2" style="width:100%" size="large" @blur="v$.form.language.$touch()">
                <el-option
                  v-for="item in languages"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item size="large" label="Copyright" :error="getErrorMessage('copyright')">
              <template #label>
                <div style="display: flex; align-items: center;">
                  <el-tooltip
                    effect="light"
                    content=""
                    placement="top"
                  >
                    <template #content>
                      <div style="width:400px">
                        Copyright - поле для указания владельца авторских прав. Вы можете указать как лейбл, так и псевдоним артиста. Если Вы не хотите указывать данные, то оставьте поле пустым.
                      </div>
                    </template>
                    <el-icon size="1.2em" class="mr2">
                      <Question />
                    </el-icon>
                  </el-tooltip>
                  Copyright
                </div>
              </template>
              <el-input v-model="form.copyright" clearable size="large" :readonly="!canEdit || artistTariff !== 'Премиум+'" @blur="v$.form.copyright.$touch()" />
            </el-form-item>
            <el-form-item label="UPC релиза">
              <template #label>
                <div style="display: flex; align-items: center;">
                  <el-tooltip
                    effect="light"
                    content=""
                    placement="top"
                  >
                    <template #content>
                      <div style="width:400px">
                        UPC релиза - поле для указания UPC кода, который нужен при переносе релиза с одного дистрибутора на другой. Если Ваш релиз новый, то оставьте поле пустым.
                      </div>
                    </template>
                    <el-icon size="1.2em" class="mr2">
                      <Question />
                    </el-icon>
                  </el-tooltip>
                  UPC релиза
                </div>
              </template>
              <el-input v-model="form.upc" clearable size="large" :readonly="!canEdit" @blur="v$.form.upc.$touch()" />
            </el-form-item>
            <el-form-item label="Отрывок в TikTok" clearable>
              <el-input v-model="form.tikTokTime" size="large" :readonly="!canEdit" @blur="v$.form.tikTokTime.$touch()" />
            </el-form-item>
            <el-form-item label="Вконтакте">
              <template #label>
                <div style="display: flex; align-items: center;">
                  Вконтакте
                  <el-icon size="2.2em" class="ml3">
                    <VK />
                  </el-icon>
                </div>
              </template>
              <el-input v-model="form.vkontakte" size="large" :readonly="!canEdit" @blur="v$.form.vkontakte.$touch()" />
            </el-form-item>
            <el-form-item label="Telegram">
              <template #label>
                <div style="display: flex; align-items: center;">
                  Telegram
                  <el-icon size="2.2em" class="ml3">
                    <TG />
                  </el-icon>
                </div>
              </template>
              <el-input v-model="form.telegram" size="large" :readonly="!canEdit" @blur="v$.form.telegram.$touch()" />
            </el-form-item>
            <el-form-item label="Instagram">
              <template #label>
                <div style="display: flex; align-items: center;">
                  Instagram
                  <el-icon size="2.2em" class="ml3">
                    <Inst />
                  </el-icon>
                </div>
              </template>
              <el-input v-model="form.instagram" size="large" :readonly="!canEdit" @blur="v$.form.instagram.$touch()" />
            </el-form-item>
            <el-form-item label="Youtube">
              <template #label>
                <div style="display: flex; align-items: center;">
                  Youtube
                  <el-icon size="2.2em" class="ml3">
                    <YT />
                  </el-icon>
                </div>
              </template>
              <el-input v-model="form.youTube" size="large" :readonly="!canEdit" @blur="v$.form.youTube.$touch()" />
            </el-form-item>
            <el-form-item style="margin: 0 !important;" label="Инструментал">
              <el-switch v-model="form.isInstrumental" :readonly="!canEdit" />
            </el-form-item>
            <div v-if="isModerator && form.status === 7" style="display: flex; justify-content: center; " class="mt5">
              <el-button type="primary" style="width:100%" :disabled="v$.$invalid" @click="saveReleaseDraft">
                Сохранить
              </el-button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { required, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import {
  ElMessage,
  ElLoading,
} from 'element-plus';

import { mapGetters } from 'vuex';
import {
  VK,
  YT,
  TG,
  Inst,
  Catalog,
  Question,
} from '@/assets/icons/index.js';

export default {
  name: 'ReleaseTab',
  props: {
    id: String,
    published: Boolean,
  },
  components: {
    VK,
    YT,
    TG,
    Inst,
    Catalog,
    Question,
  },
  data: () => ({
    v$: useVuelidate(),
    collaborator: null,
    releaseId: null,
    genres: [],
    artist: null,
    imagePath: null,
    languages: null,
    saveDraftLoader: false,
    artistTariff: 'Базовый',
    artistName: null,
    form: {
      name: '',
      version: '',
      headArtists: [],
      copyright: '',
      upc: '',
      releaseLabel: '',
      publisherLabel: '',
      language: '',
      collaborators: [],
      genre: '',
      releaseDate: '',
      preOrderDate: '',
      image: '',
      type: 0,
      isInstrumental: false,
      obscenities: false,
      tikTokTime: null,
      status: null,
      vkontakte: null,
      telegram: null,
      instagram: null,
      youTube: null,
    },
    loaders: {
      openDraft: false,
      genres: false,
    },
    files: [],
    filesInfo: null,
    preview: '',
    releaseTypes: null,
  }),
  async mounted() {
    this.initRelease();
  },
  methods: {
    hideMainArtistCloseButton(name) {
      if (this.artistTariff === 'Премиум+') {
        return false;
      }
      return name === this.artistName;
    },
    async initRelease() {
      const loading = ElLoading.service({ //eslint-disable-line
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
        target: '#form',
      });
      this.loaders.openDraft = true;

      // await this.getGenres();
      // await this.getLanguages();
      this.releaseId = this.id;
      if (this.releaseId) {
        await this.getDraftRelease();
      } else {
        await this.saveReleaseDraft();
        await this.getDraftRelease();
      }
      loading.close();
      this.loaders.openDraft = false;
      this.$emit('update:form', this.form);
    },
    deleteColloborator(i) {
      this.form.collaborators.splice(i, 1);
    },
    deleteHeadArtist(i) {
      this.form.headArtists.splice(i, 1);
    },
    validateForm() {
      this.v$.form.$touch();
    },
    addMainArtist() {
      this.form.headArtists.push(this.artist);
      this.artist = null;
    },
    addColloborator() {
      this.form.collaborators.push(this.collaborator);
      this.collaborator = null;
    },
    selectNode(node, parentNode) {
      const selectedGenre = `${parentNode.parent.data.name ? `${parentNode.parent.data.name} / ` : ''}${node.name}`;
      this.$emit('update:selectedGenre', selectedGenre);
    },
    async getDraftRelease() {
      await this.$axios.get(`/release-catalog/get-release-draft-by-id/${this.releaseId}`)
        .then((res) => {
          const { data } = res;
          const release = data.data;
          const { additionalInfo } = data;
          this.genres = additionalInfo.genres;
          this.releaseTypes = additionalInfo.types;
          this.languages = additionalInfo.languages;
          this.$emit('update:languages', additionalInfo.languages);
          this.$emit('update:releaseTypes', additionalInfo.types);
          this.form = {
            language: release.language,
            name: release.name,
            version: release.version,
            upc: release.upc,
            releaseLabel: release.releaseLabel,
            publisherLabel: release.publisherLabel,
            collaborators: release.collaborators,
            genre: release.genre ? release.genre.id : null,
            releaseDate: release.releaseDate,
            preOrderDate: release.preOrderDate,
            type: release.type,
            obscenities: release.obscenities,
            tikTokTime: release.tikTokTime,
            isInstrumental: release.isInstrumental,
            status: release.status,
            vkontakte: release.vkontakte,
            telegram: release.telegram,
            instagram: release.instagram,
            youTube: release.youTube,
          };
          this.artistTariff = release.artistTariff;
          this.artistName = release.artistName;
          if (this.artistTariff === 'Премиум+') {
            this.form.headArtists = release.headArtists;
            this.form.copyright = release.copyright;
          } else {
            this.form.headArtists = this.id ? release.headArtists : [this.userName];
            // this.form.headArtists = this.id ? release.headArtists : [{ pseudonym: this.userName, links: [{}] }];
            if (this.isModerator) {
              this.form.copyright = release.copyright;
            } else if (this.artistTariff === 'Премиум') {
              this.form.copyright = this.userName;
            } else if (this.artistTariff === 'Базовый') {
              this.form.copyright = 'Mediadice Music Ecosystem';
            } else {
              this.form.copyright = release.copyright;
            }
          }
          if (release.genre) {
            const selectedGenre = `${release.genre.parent ? `${release.genre.parent.name} / ` : ''}${release.genre.name}`;
            this.$emit('update:selectedGenre', selectedGenre);
          }
          this.$emit('set-comment', release.comment);
          this.$emit('set-user-comment', release.userComment);
          this.$emit('has-file', !!release.imagePath);
          this.preview = release.imagePath;
          this.$emit('update:imagePath', release.imagePath);
          if (this.$route.name === 'EditDraftRelease') {
            this.validateForm();
          }
        })
        .catch((e) => {
          ElMessage({ message: e, type: 'error' });
        });
    },
    async saveReleaseDraft() {
      if (!this.canEdit) {
        return;
      }
      this.$emit('update:draftLoader', true);
      this.openDraft = true;
      const headArtists = this.form.headArtists.reduce((acc, cur) => {
        acc.push(cur);
        return acc;
      }, []);
      const collaborators = this.form.collaborators.reduce((acc, cur) => {
        acc.push(cur);
        return acc;
      }, []);
      const request = {
        ...this.form,
        collaborators: collaborators || null,
        headArtists,
        releaseDate: this.form.releaseDate ? this.$moment(this.form.releaseDate).format('YYYY-MM-DD HH:MM:SS') : null,
        preOrderDate: this.form.preOrderDate ? this.$moment(this.form.preOrderDate).format('YYYY-MM-DD HH:MM:SS') : null,
      };
      const formData = new FormData();
      for (const it in request) {
        if (it === 'collaborators' || it === 'headArtists') {
          formData.append(`${it}`, JSON.stringify(request[it]));
        } else if (request[it]) {
          formData.append(`${it}`, request[it]);
        }
      }
      if (this.releaseId) {
        formData.append('id', this.releaseId);
      }
      formData.append('image', this.files[0]);
      await this.$axios.post('/release-catalog/save-release-draft', formData)
        .then((res) => {
          const { data } = res;
          if (data && !this.releaseId) {
            this.releaseId = data;
          }
          this.$emit('set-id', data);
          if (this.isModerator) {
            ElMessage({ message: 'Релиз успешно сохранён', type: 'success' });
          }
        }).catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        }).finally(() => {
          this.$emit('update:draftLoader', false);
        });
    },
    getErrorMessage(fieldName) {
      const message = this.$errorsMessages(this.v$, `form.${fieldName}`);
      if (!this.loaders.openDraft) {
        this.$emit('add-error', fieldName, message);
      }
      return message;
    },
    async  createFile(file) {
      this.filesInfo = file.name.split('.');
      this.files.push(file);
      this.$emit('has-file', true);
    },
    async  loadImage(e) {
      e.preventDefault();
      const files = e.target.files || e.dataTransfer.files;

      const data = this;
      const reader = new FileReader();
      // const sizeOf = require('image-size');

      reader.readAsDataURL(files[0]);
      for (const file of files) {
        const ext = `.${file.name.split('.').pop().toLowerCase()}`;
        if (ext !== '.jpg') {
          ElMessage({ message: 'Допустимое расширение файла .jpg', type: 'error' });
          this.$refs.inputFile.value = null;
          return;
        }
        // const sizeOf2 = this.sizeOf;
        reader.onload = await function (e) { // eslint-disable-line

          const img = new Image();
          img.onload = () => {
            if (img.width >= 1500 && img.width <= 3000 && img.height >= 1500 && img.height <= 3000) {
              data.preview = e.target.result;
              data.createFile(file);
            } else {
              ElMessage({ message: 'Размер картинки должен быть от 1500px до 3000px', type: 'error' });
            }
          };

          img.src = e.target.result;
          this.$refs.inputFile.value = null;
        };
      }
    },
  },
  computed: {
    ...mapGetters(['isModerator', 'getTariff', 'userName']),
    // headArtists() {
    //   return this.form.headArtists.map((it) => it.pseudonym).join(',');
    // },
    collaborators() {
      return this.form.collaborators.join(',');
    },
    canEdit() {
      return !this.isModerator || (this.isModerator && (this.form?.status === 7 || this.form?.status === 3));
    },
  },
  watch: {
    preview() {
      this.$emit('update:imagePath', this.preview);
    },
    id() {
      this.initRelease();
    },
    form() {
      this.$emit('update:form', this.form);
    },
  },
  validations() {
    return {
      form: {
        language: { required },
        name: { required, minLength: minLength(2) },
        version: '',
        headArtists: { required },
        copyright: { minLength: minLength(2) },
        upc: '',
        releaseLabel: '',
        publisherLabel: '',
        collaborators: {},
        genre: { required },
        releaseDate: { required },
        preOrderDate: { required },
        image: '',
        type: { required },
        obscenities: false,
        tikTokTime: false,
        isInstrumental: false,
        vkontakte: '',
        telegram: '',
        instagram: '',
        youTube: '',
      },
    };
  },

};

</script>

<style lang="scss">

@media screen and (max-width: 1500px) {
  .date-row {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1500px) {
  .date-row{
  #release {
    flex-basis: 120%;
  }
  #preOrder {
    margin-left: 0.2em;
    .el-form-item__label{
    width: 13em !important;
    }
  }
}
}

.collobarator{
  margin: 0.5em 0.5em;
  padding-left: 0.5555555555555556em;
  align-items: center;
  display: flex;
   flex-direction: row;
   justify-content: space-between;
     border-radius: 0.5555555555555556em;

    &:hover{
      background-color: $subGrey3;
     }
}

.release-image {
  width:14.444444444444445em;
  height:14.444444444444445em;
  border-radius:0.7777777777777778em; z-index:1;margin-bottom:1.1111111111111112em
}

// .image-slot {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   background: var(--el-fill-color-light);
//   color: var(--el-text-color-secondary);
//   font-size: 1.6666666666666667em;
//   color:$primaryBlue
// }

 .obscenities-switch  .el-radio-button{
  background: #151515 ;

  .el-radio-button__inner {
    padding: 0.5em 1em !important;
  }

  .el-radio-button__inner{
    padding: 0.5em 1em !important;
    border-color:#3290FF !important;
     color: white !important;
  }
    &:first-child{
      border-radius: 0.8333333333333334em 0 0  0.8333333333333334em;
      .el-radio-button__inner{
       border-radius: 0.8333333333333334em 0 0  0.8333333333333334em;
      }
    }
    &:last-child{
      border-radius:0  0.8333333333333334em  0.8333333333333334em 0;
      .el-radio-button__inner{
        border-radius:0  0.8333333333333334em  0.8333333333333334em 0;
      }

 }
 }
  .obscenities-switch  .is-active{
      .el-radio-button__inner {
          background-color: #3290FF!important;
          box-shadow: none;
      }

    }

</style>
