<template>
  <div>
    <el-form label-width="30%">
      <div class="d-flex flex-row align-center justify-center mb1">
        <div class="ar-h3">
          Артист {{ !iter || iter === 0 ? '' : iter+1 }}
        </div>
        <el-button :disabled="form.pseudonym.length === 0" link type="primary" style="padding: 1em !important; height:2em; width:3em;" @click="$emit('delete', form)">
          <el-icon size="20">
            <Delete />
          </el-icon>
        </el-button>
      </div>
      <div style="display: flex; flex-direction: row; align-items: top;">
        <el-form-item style="flex-basis:100%" label="Имя">
          <el-input v-model="form.pseudonym" size="large" :placeholder="placeholder" />
        </el-form-item>
      </div>
      <div>
        <PlatformField
          v-for="platform in form.links"
          :key="platform.id"
          :iter="platform.id"
          :info="platform"
          @update="updateForm"
        />
        <div class="d-flex flex-row justify-end">
          <div style="flex-basis: 70%;">
            <el-button style="padding: 0.5em !important;" link @click="form.links.push(
              {
                link: null,
                platform: null,
                id: form.links.length,
              }
            )"
            >
              <el-icon style="margin-right:1em" type="primary">
                <Plus />
              </el-icon>
              Добавить ещё одну ссылку на артиста
            </el-button>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import PlatformField from './PlatformField.vue';

export default {
  name: 'ArtistForm',
  components: {
    PlatformField,
  },
  props: {
    placeholder: String,
    iter: Number,
    info: Object,
    modal: Boolean,
  },
  data: () => ({
    form: {
      pseudonym: '',
      links: [
        {
          link: null,
          platform: null,
        },
      ],
    },
  }),
  mounted() {
    this.form = {
      ...this.info,
    };
  },
  methods: {
    updateForm(i, form) {
      this.form.links[i] = { ...form, id: i };
    },
  },
  watch: {
    modal() {
      if (this.modal) {
        this.form = {
          ...this.info,
        };
      }
    },
    form: {
      handler() {
        this.$emit('update', this.iter, this.form);
      },
      deep: true,
    },
  },

};
</script>
