<template>
  <div>
    <el-form class="d-flex flex-row">
      <el-form-item style="width:30%" class="pr3">
        <el-select v-model="form.platform" size="large" placeholder="Выбрать" :items="platforms">
          <el-option
            v-for="item in platforms"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item style="width:70%">
        <el-input v-model="form.link" size="large" placeholder="Вставте ссылку" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  props: {
    info: Object,
    iter: Number,
  },
  data: () => ({
    form: {
      platform: '',
      link: null,
    },
    platforms: [
      { name: 'Spotify', id: 'spotify' },
      { name: 'Apple Music', id: 'apple' },
      { name: 'Yandex Music', id: 'yandex' },
      { name: 'VK Music', id: 'vk' },
    ],
  }),
  mounted() {
    console.log(this.info);
    this.form = {
      platform: this.info.name || this.info.platform,
      link: this.info.link,
    };
    console.log(this.form);
  },
  watch: {
    form: {
      handler() {
        this.$emit('update', this.iter, this.form);
      },
      deep: true,
    },
  },

};
</script>
