<template>
  <div style="display: flex;">
    <div class="pa5 ar-card" style="border-radius:1.9444444444444444em; flex-basis:700px; display: flex; flex-direction: column; justify-content: center;">
      <div style="font-size: 1.3888888888888888em;" class="mb3">
        {{ header }}
      </div>
      <el-input
        v-model="form.comment"
        class="mb3"
        placeholder="Комменатарий"
        :rows="10"
        type="textarea"
      />
      <div style="display: flex; flex-direction: row; justify-content: end;" class="mb4">
        <div @click="chooseTemplateModal=true;">
          <el-icon size="20">
            <Copy />
          </el-icon>
        </div>
        <div class="ml2">
          <el-icon size="20" @click="addTemplateModal=true;">
            <Release />
          </el-icon>
        </div>
      </div>
      <el-input
        v-if="status === 4"
        v-model="form.upc"
        class="mb3"
        placeholder="UPC"
        :rows="2"
      />
      <div v-if="status===6">
        <el-button :loading="loaders.approve" :disabled="!form.comment" type="primary" @click="changeStatus(9)">
          Отклонить удаление
        </el-button>
        <el-button :loading="loaders.reject" type="danger" @click="deleteRelease">
          Удалить
        </el-button>
      </div>
      <div v-else-if="status===7">
        <el-button :loading="loaders.approve" :disabled="!form.comment || loaders.approve" type="danger" @click="changeStatus(9)">
          Отклонить изменение
        </el-button>
        <el-button :loading="loaders.approve" type="primary" @click="changeStatus(9)">
          Вернуть
        </el-button>
      </div>
      <div v-else-if="status === 3">
        <el-button :loading="loaders.approve" :disabled="loaders.reject" type="primary" @click="changeStatus(4)">
          Закончить проверку
        </el-button>
        <el-button :loading="loaders.reject" :disabled="!form.comment || loaders.approve" type="danger" @click="rejectRelease">
          Отклонить релиз
        </el-button>
      </div>
      <div v-else-if="status === 4">
        <el-button :loading="loaders.approve" :disabled="loaders.reject" type="primary" @click="approveRelease">
          Опубликовать релиз
        </el-button>
        <el-button :loading="loaders.reject" :disabled="!form.comment || loaders.approve" type="danger" @click="rejectRelease">
          Отклонить релиз
        </el-button>
      </div>
    </div>
    <AddTemplateModal v-model="addTemplateModal" />
    <ChooseTemplateModal v-model="chooseTemplateModal" @set-template="(e) => {form.comment = e}" />
  </div>
</template>

<script>
import {
  ElMessage,
} from 'element-plus';
import {
  // Home, Catalog,
  // Analityc,
  // Finance,
  Copy,
  Release,
  // User,
} from '@/assets/icons/index.js';
import AddTemplateModal from '@/components/modals/AddTemplateModal.vue';
import ChooseTemplateModal from '@/components/modals/ChooseTemplateModal.vue';

export default {
  name: 'ApproveReleaseTab',
  props: {
    id: String,
    status: Number,
  },
  components: {
    Release,
    Copy,
    AddTemplateModal,
    ChooseTemplateModal,
  },
  data: () => ({
    addTemplateModal: false,
    chooseTemplateModal: false,
    form: {
      comment: null,
      upc: null,
    },
    loaders: {
      approve: false,
      reject: false,
    },
  }),
  computed: {
    header() {
      switch (this.status) {
        case 6:
          return 'Удалить релиз?';
        case 7:
          return 'Вернуть релиз?';
        case 3:
          return 'Закончить проверку релиза?';
        default:
          return 'Опубликовать релиз?';
      }
    },
  },
  methods: {
    async changeStatus(status) {
      this.loaders.approve = true;
      const req = {
        status,
        comment: this.form.comment || null,
      };
      await this.$axios.post(`release-request/change-release-status-by-id?releaseRequestId=${this.$route.query.requestId}`, req)
        .then(() => {
          ElMessage({ grouping: true, message: status === 4 ? 'Релиз проверен' : 'Релиз возвращён', type: 'success' });
          this.$router.push('/moderation-list');
        }).catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        }).finally(() => {
          this.loaders.approve = false;
        });
    },
    async deleteRelease() {
      this.loaders.reject = true;
      await this.$axios.delete(`release-catalog/delete-release-by-id/${this.id}`)
        .then(() => {
          ElMessage({ grouping: true, message: 'Релиз успешно удалён', type: 'success' });
          this.$router.push('/moderation-list');
        }).catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        }).finally(() => {
          this.loaders.reject = false;
        });
    },
    // async acceptReview(status) {
    //   const req = {
    //     status,
    //   };
    //   this.loaders.approve = true;
    //   this.$axios.post(`release-request/change-release-status-by-id?releaseRequestId=${this.$route.query.requestId}`, req)
    //     .then(() => {
    //       this.$router.push('/moderation-list');
    //       ElMessage({ message: 'Трек успешно опубликован', type: 'success' });
    //     })
    //     .catch((e) => {
    //       ElMessage({ message: e.response.data[0], type: 'error' });
    //     })
    //     .finally(() => {
    //       this.loaders.approve = false;
    //     });
    // },
    async approveRelease() {
      this.loaders.approve = true;
      const req = {
        upc: this.form.upc,
      };
      this.$axios.post(`release-request/accept-by-release-request-id/${this.$route.query.requestId}`, req)
        .then(() => {
          this.$router.push('/moderation-list');
          ElMessage({ message: 'Релиз успешно опубликован', type: 'success' });
        })
        .catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        })
        .finally(() => {
          this.loaders.approve = false;
        });
    },
    async rejectRelease() {
      this.loaders.reject = true;
      const req = {
        comment: this.form.comment,
      };
      this.$axios.post(`release-request/deny-by-release-request-id/${this.$route.query.requestId}`, req)
        .then(() => {
          this.$router.push('/moderation-list');
          ElMessage({ message: 'Трек успешно отклонён', type: 'success' });
        })
        .catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        })
        .finally(() => {
          this.loaders.reject = false;
        });
    },
  },
};
</script>
